import { Post } from "@prmf/site-data";
import { t } from "@prmf/site-router";
import { Link, useFetcher } from "@remix-run/react";
import { useEffect } from "react";
import NewsItems from "./NewsItems";

type Props = {
  source?: string;
  limit?: number;
  tag?: string;
};

export default function NewsWidget(props: Props) {
  const fetcher = useFetcher<{ posts?: Post[] }>();
  const target = props.source || "/news";

  const params = new URLSearchParams({
    limit: (props.limit || 3).toString(),
    tag: props.tag || "",
  });

  useEffect(() => {
    if (fetcher.state === "idle" && !fetcher.data) {
      fetcher.load(`${target}?${params.toString()}`);
    }
  }, [fetcher, target]);

  if (!fetcher.data?.posts?.length) return null;

  return (
    <section className="border-t">
      <div className="container space-y-10 py-20">
        <NewsItems className="xl:!grid-cols-3" posts={fetcher.data?.posts} />
        <Link className="button-accent" to={target}>
          More {target === "/news" ? "Latest news" : t(target || "", "title")}
        </Link>
      </div>
    </section>
  );
}
